/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const NikkeGuidesRei2: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Rei Ayanami: Tentative Name guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_rei2.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Rei Ayanami: Tentative Name Guide & Review</h1>
          <h2>
            A guide & review for Rei Ayanami: Tentative Name in NIKKE: Goddess
            of Victory.
          </h2>
          <p>
            Last updated: <strong>03/Mar/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Rei Ayanami: Tentative Name" />
              <div className="employee-container for-nikke">
                <NikkeCharacter
                  mode="icon"
                  slug="rei-ayanami-tentative-name"
                  enablePopover
                />
              </div>
              <p>
                We decided to make the foreword for this guide an interesting
                fact about Rei’s unique name! Rei Ayanami: (Tentative Name) is
                one heck of a name given by Shift Up, isn’t it? A few people
                here might be wondering why Shift Up decided to name her as
                such. Be assured, this is not a mistake or an oversight. The
                real reason is that in the Rebuild Series of Evangelion, this
                Rei Ayanami was not actually the real one. She was merely a
                clone, and her real name is actually “Ayanami-Type No.006”. She
                was given the name of Rei Ayanami only because she resembled the
                real Rei. Hence, it is a name without any meaning to her, given
                to her just because she looks like the real Rei Ayanami. On to
                the review!
              </p>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                Rei Ayanami: (Tentative Name), who we will call Rei 2.0 from now
                on, is a very simple unit to understand. She has gimmicks here
                and there, but all in all she is your average element-based raid
                DPS who will shine brightly against Iron bosses (since Iron is
                weak to Wind). However, Rei 2.0’s role is not quite restricted
                to just being a DPS for Wind Weak Bosses. This is because not
                only is she quite decent in Campaign, she is also a very decent
                unit for PvP. She is also not just a pure DPS for Raids, but
                also buffs allies in her team with minor buffs and unique
                synergies with MG units that burst. The best synergy she has is
                with Asuka: Wille, who does not particularly require her to work
                but does appreciate the buffs Rei 2.0 provides her with. This
                synergy section is talked about in detail in the guide, but we
                will tell you this much.{' '}
                <strong>
                  Contrary to people who have been saying that Asuka needs Rei,
                  it is actually Rei Ayanami: (Tentative Name) who needs Asuka:
                  Wille to deal DPS, and not the other way around!
                </strong>
              </p>
              <p>
                As a DPS, she is a unit very similar to our very own Scarlet
                1.0, but is probably best compared to the unit she is the clone
                of: Rei Ayanami (from EVA 1.0). Rei 2.0 can deal decent damage
                from her normal attacks and skills during her own burst. Unlike
                drunk Scarlet or Rei 1.0, Rei 2.0 does not buff herself a lot
                when it is not her own burst, hence she is not a good off-burst
                unit. This is something Rei 1.0 and Scarlet have an edge in.
              </p>
              <p>
                Rei 2.0’s flexibility and usability in other game modes makes
                use of her Nuke in her Burst. Her personal DPS mostly gets a
                decent boost when it is her own burst, which also happens to
                buff her nuke! This buff to her nuke makes her the 2nd highest
                nuke for PvP, only behind Cinderella. This nuke is also a
                powerful screen clear ability for Campaign since it is an
                instant screen clear, making her the 3rd usable instant screen
                clear unit. Scarlet 1.0 for Electric, Rei 1.0 for fire, and now
                Rei 2.0 for Wind. Iron and Water await their high damage instant
                AoE!
              </p>
              <h5>Should I get this unit?</h5>
              <p>
                If you have Asuka: WILLE or are planning to get her, then the
                answer is a resounding <strong>YES</strong>. However, if you are
                skipping Asuka: WILLE, then it’s probably a no and a relatively
                easy skip since Rei’s best use is alongside Asuka 2.0. Asuka 2.0
                is a much worthier investment even when alone, so your order
                should be Asuka: Wille first, and only then think about Rei 2.0.
                However, despite Rei's lower priority, she has a better value
                proposition for PvP-ers thanks to her very strong AoE Nuke and
                low feeding to Jackal.
              </p>
              <p>
                To add, EVA 2 units are unlikely to be replaced soon in Raids.
                We don't have many good Wind DPS units at the moment, and even
                if we did, the EVA 2s would still be universally good against
                all elements. Rei, however, is the weaker of the two and might
                not see much use against neutral elements unless you have a very
                invested Rei 2.0. Both of them still shine in Bossing and are
                usable in Campaign and will definitely compete for a spot in one
                team with SBS. And they're limited—you won't see them return
                unless they strike another deal with EVA (very unlikely). Even
                then, it's not guaranteed.
              </p>
              <p>
                As far as <strong>dupes</strong> are concerned, we must divide
                you into 3 categories. Firstly, Competitive. Competitive PvP and
                Raid players should ideally MLB Rei Ayanami, especially for the
                upcoming Wind Weak Raid with ‘Massive Object Q’, where she is
                expected to deal a lot of damage. Secondly, if Rei is your
                waifu, MLB her for the extremely pretty Lobby screen (they
                really cooked this time for lobby screens!). If you do not fall
                within these categories, one copy is absolutely fine. She does
                her job well even with one copy, so you do not need to worry
                about getting dupes of her overall. Her impact is also
                relatively lower compared to Asuka: Wille. If you plan to get
                dupes, Asuka is a higher priority.
              </p>
              <p>
                What about <strong>Golden Mileage?</strong> Well, if you already
                have Asuka and somehow are unable to get Rei from pulls, then
                consider ticketing her instead. We know tickets should be saved
                for 1% Pilgrim banners, but you definitely don't want to end up
                not having Rei at the end of the event since she will NOT come
                back! Maybe save up and see whether you can squeeze out a bit
                more gems before the event ends.{' '}
                <strong>
                  Definitely save for half anniversary pilgrim after the collab
                  ends if you do not have Golden Mileage left for half
                  anniversary!
                </strong>
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <p>
                Rei’s kit is not that hard to explain so we will try to keep it
                as simple as possible. There are only a couple of gimmicks she
                does that we need to be wary of. Rei’s base weapon damage is
                towards the weaker side when compared to other Meta DPS, with
                only 14.71% of ATK as damage. Units like Asuka (18.76%), Scarlet
                (27.08%), Grave (17.91%) have higher base weapon multipliers
                than her. Units like Snow White and Brid have similar
                multipliers as ReiTN, which is not a great start.
              </p>
              <p>
                Rei’s reload time is also 1.5s, which is .5s more than the
                expected 1s reload time. This makes Max Ammo lines on her OL
                gear more important, since her DPS depends on how many bullets
                she fires, as we will read now in her Skills.
              </p>
              <h5>Annihilation Support</h5>
              <blockquote>
                <p>
                  ■ Activates after landing 18 normal attack(s). Affects the
                  target when the target is in Anti A.T. Field status.
                </p>
                <p>Deals 590. 64% of final ATK as additional damage.</p>
                <p>Stack count of Anti A.T. Field ▲ 10.</p>
                <p>
                  ■ Activates after landing 7 normal attack(s) when self is in
                  Attack State status. Affects the target(s).
                </p>
                <p>Deals 286.37% of final ATK as additional damage.</p>
                <p>
                  ■ Activates when entering Full Burst. Affects all allies in
                  Annihilation State status.
                </p>
                <p>
                  Units affected by Annihilation State's additional effect ▲ 1
                  for 9 sec.
                </p>
                <p>
                  Attack range of Annihilation State's additional effect ▲ 500%
                  for 9 sec.
                </p>
                <p>ATK ▲ 17.6% of caster's ATK for 9 sec.</p>
              </blockquote>
              <p>
                This is the bread and butter of her DPS. Let’s go over each of
                the 3 parts of her Skill 1 one by one.
              </p>
              <p>
                The first part activates every 18 bullets shot and affects only
                those units that have Anti AT Field status.{' '}
                <strong>
                  This status is only inflicted by Asuka:Wille on enemies,
                  making her a necessity to activate this skill!
                </strong>{' '}
                The 590.64% additional damage can be simplified to 32.813%
                additional damage dealt per bullet, which is a huge increase to
                her DPS! As thanks to Asuka allowing Rei to deal this additional
                damage, Rei increases the stacks of her AT field, allowing this
                AT field status to reach max stacks much faster (from 5 seconds
                down to 3 seconds), increasing the value of this buff from
                18.675% over 10s to 21.165% over 10s, which in turn increases
                the team’s damage overall as well. Be vary that Rei does NOT
                increase the uptime of the buff, just the value of the buff over
                it’s active period is increased due to faster stacking.
              </p>
              <p>
                The second part of this skill activates during Attack state,
                which is Rei’s burst. This 286.37% additional damage can be
                simplified to 40.91% additional damage per bullet! This
                increases her DPS significantly as well, since now she is
                essentially dealing 6 times her base weapon’s damage per bullet!
                On bosses with low core uptime, this is some serious damage
                being dealt by Rei 2.0!
              </p>
              <p>
                <strong>
                  The third part of her skill 1 is meant only and only for
                  Asuka: Wille.
                </strong>{' '}
                This skill increases the targets affected by Asuka’s
                “Annihilation State's additional effect” from 2 raptures to 3
                raptures. (Note: does NOT increase uptime of this skill). This
                specific part is only relevant for Campaign, and does not matter
                for bossing. What does matter everywhere is that she turns the
                additional damage dealt by Asuka’s skill into an AoE by
                increasing the explosion radius of this additional damage. She
                also buffs Asuka’s ATK by 17.6% of Caster’s ATK, which is where
                investments and dupes into Rei can come handy, which is quite a
                decent buff to her overall DPS output every time Asuka is in her
                burst. A wordy skill, but a very useful one for Rei.
              </p>
              <h5>Maintenance and Resupply</h5>
              <blockquote>
                <p>
                  ■ Activates when entering Full Burst. Affects all allies with
                  a Machine Gun, who have used their Burst Skills.
                </p>
                <p>MG heating up speed ▲ 100% for 13 sec.</p>
                <p>■ Activates when entering Full Burst. Affects all allies.</p>
                <p>ATK ▲ 11.61% of caster's ATK for 10 sec.</p>
              </blockquote>
              <p>
                Another 2 part skill. Firstly, if any other Machine Gun bursts
                in the current burst rotation, ReiTN halves the wind up of their
                gun. This means that if an MG unit stops shooting for some
                reason, to reload or to take cover, Rei will allow them to start
                shooting again at max wind up speed much faster than usual. This
                is a useful skill for MG units since winding up causes severe
                DPS loss, and is a major reason why Max Ammo and Reload Speed is
                highly appreciated by MG units. There is only one unit who’s
                wind-up is not halved, but is instead returned to normal, and
                that’s Asuka: WIlle. This skill is part of the synergy between
                Asuka and Rei. Do keep in mind that this skill is not entirely
                super strong as initially thought it would be.
              </p>
              <p>
                The second part of this skill is a Caster’s ATK buff to all
                allies, which activates every burst rotation.
              </p>
              <h5>Attack State</h5>
              <blockquote>
                <p>■ Affects self.</p>
                <p>Attack State:</p>
                <p>Attack Damage ▲ 35.9% for 10 sec.</p>
                <p>ATK ▲ 63.36% of caster's ATK for 10 sec.</p>
                <p>■ Affects all enemies.</p>
                <p>Deals 990.2% of final ATK as Burst Skill damage.</p>
              </blockquote>
              <p>
                The first part of her burst is her Attack State, in which she
                buffs herself with ATK and Attack Damage buffs. These buffs then
                further increase the damage in the second part of her skill,
                which is a high damage nuke. For PvP, these buffs are very
                important. With both the buffs and the Nuke being in the same
                skill, the damage ramps up hard for each level upgraded. Hence,
                there is a big difference when Rei 2.0’s burst is lvl7 and when
                it is lvl10. In PvP, it can be the difference between a win and
                a loss.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Usage Analysis and Tier List" />
              <h5>
                Campaign (LD) - <strong className="ss">SS</strong> | Campaign
                (HD) - <strong className="ss">SS</strong>
              </h5>
              <p>
                Rei’s job in Campaign is that of a DPS unit that can instantly
                clear screens. We already have a unit that does that for wind -
                Scarlet: Black Shadow, but SBS is not a screen clear in the High
                Deficit campaign. She needs to activate her Skill 1 multiple
                times in order to finally kill all of the units on the screen,
                and her effectiveness is reduced if there are a lot of units on
                the screen. ReiTN, alternatively, thrives when there are a ton
                of units present since she can kill a huge amount of squishy
                raptures at once with her nuke. This is her major role in
                Campaign. During her burst, she can deal a high amount of damage
                with her weapon as well. If paired alongside Asuka: Wille, she
                can allow her to deal AoE additional damage procs, and also
                increases the units that have Damage Taken Debuff from 2 to 3,
                while also allowing the debuff to get max stacks in just 3
                seconds.
              </p>
              <p>
                Rei’s AoE is very similar to that of drunk Scarlet, who has been
                used throughout Nikke’s campaign time and time again on stages
                that need an instant wipe when there's too many units present
                that can one shot you. In the new element focused meta, Rei 2.0
                is now here to do that for Wind weak stages, which will be her
                major strength and use!
              </p>
              <h5>
                Bossing - <strong className="s">S</strong>
              </h5>
              <p>
                Raids are pretty much the endgame of Nikke right now, and Rei’s
                rating is floating around SS tier and S tier as far as we are
                concerned.
              </p>
              <p>
                Rei is a strong wind DPS, that is already a given. Around
                S.Sakura tier of DPS. She can stage 9 Kraken in Anomaly
                Interception alongside Asuka:Wille, making one of the best
                Kraken teams being AsukaW+ReiTN together! SBS + Alice and Asuka
                + Rei also form a very solid team structure for Union Raids,
                where in the new Hard Mode we have to hit Wind Weak bosses with
                multiple attacks sometimes. Wind is the first element to have 2
                super teams for Union Raid.
              </p>
              <p>
                However, Rei 2.0 does more than just deal damage. Her synergy
                with Asuka:Wille, an SSS tier DPS by our standards, is something
                that can allow her to stand out even against neutral elements!
                She provides Asuka with roughly a 15~20% damage buff overall,
                varying slightly depending on investments and team. It is true
                however that it may be more fruitful to use element based DPS,
                if any are left to fill slots, over ReiTN. The 15~20% damage
                buff and Rei’s personal DPS can be covered by elemental damage
                OL if the DPS is anywhere around Rei’s damage quite easily. And
                we can assure you that reaching Rei’s DPS is not outlandish by
                today's standards. Hence why we rated her S tier, since we feel
                that Rei’s use is strongly concerned with Wind Weak bosses only.
              </p>
              <p>
                The best chance Rei has of being used on non Wind element bosses
                is if the boss does not have a core, or has low core uptime.
                This reduces the usability of a lot of DPS, and is a situation
                where both AsukaW and ReiTN excel in and can be used effectively
                in. Otherwise, there is not much hope for Rei outside of the
                Wind element.
              </p>
              <h5>
                PVP - <strong className="ss">SS</strong>
              </h5>
              <p>
                Nuke and win, that’s all the PvP Rei is concerned about. Her
                high damage nuke is the second highest nuke in terms of value,
                only behind our beautiful Cinderella, and only requires one
                buffer to kill an Attacker unit. With a double buff, you are
                possibly guaranteed a wipeout unless Max HP buffers, Noah, Bay
                or collection items have something to say about it.
              </p>
              <p>
                Rei’s burst gen is not anything special however, and she is
                hence forced to be in teams similar to those used for units like
                Cinderella, 2B, Maid Privaty, Ein Quency: Escape Queen, or
                Scarlet. Scarlet’s reflect ability from her skill 2 and
                Cinderella’s higher damage nuke does mean that they are better
                units and have more flexibility in team-building. Otherwise,
                ReiTN should have an easy time fitting into general nuke teams.
                She is also a Wind unit, which means she can try to snipe off
                units like Red Hood, Jackal, or Centi - who are common Iron
                units used in PvP. Some big names here that she can target.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <p>
                Rei gets the average DPS treatment. Elemental Damage, ATK and
                some Ammo can help her deal decent damage. Her OL gear breakdown
                is as follows:
              </p>
              <ul>
                <li>
                  <strong>Essential:</strong> -
                </li>
                <li>
                  <strong>Ideal:</strong> 4× ELE, 4× ATK, 2-4× Ammo
                </li>
                <li>
                  <strong>Passable:</strong> 2× Crit Damage, 2× Crit Rate
                </li>
                <li>
                  <strong>Priority:</strong> Medium
                </li>
              </ul>
              <h5>Skill Investments</h5>
              <ul>
                <li>
                  <strong>Skill 1: 7~10</strong>
                </li>
                <ul>
                  <li>
                    This skill is what matters for Rei’s weapon based DPS. This
                    is where all her additional damage lies. Her ATK buff for
                    Asuka is also in this skill. We recommend getting the skill
                    to 10 as first priority, but keeping it at 7 is also fine.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 4/7/10</strong>
                </li>
                <ul>
                  <li>
                    Third in priority, but still a decent skill to upgrade. We
                    recommend getting this skill to 4 first, and then 7. If you
                    have extra mats, you can get this to 10 eventually, but the
                    damage increase is minimal.
                  </li>
                </ul>
                <li>
                  <strong>Burst Skill:7~10</strong>
                </li>
                <ul>
                  <li>
                    This skill increases Rei’s personal damage by providing her
                    with ATK and Attack Damage buffs. Hence, it is an important
                    one to upgrade. This is second in priority to upgrade for
                    Rei. <strong>For PvP</strong> and Campaign,{' '}
                    <strong>
                      this skill is the top priority skill to upgrade instead
                      since her nuke value and her nuke buffs both scale with
                      this skill
                    </strong>
                    .
                  </li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="rei-ayanami-tentative-name" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_3_sm.png"
                alt="Guide"
              />
              <StaticImage
                src="../../../images/nikke/generic/cube_4_sm.png"
                alt="Guide"
              />
              <StaticImage
                src="../../../images/nikke/generic/cube_13_sm.webp"
                alt="Guide"
              />
              <p>
                As is common, Resilience cube for reload teams (Crown, Maid
                Anchor and Maid Mast, Privaty), otherwise use Bastion cube.
                Destruction cube is the best choice in teams like Crown-Priv or
                Maid Duo team since reload speed is already so high that we can
                ignore it. Instead, using Destruction cube to increase damage
                dealt to parts from her base weapon should be the best way to
                increase her damage.
              </p>
              <h5>Doll Investments</h5>
              <p>
                We recommend getting ReiTN to SR5 doll as a start since she is a
                DPS unit and also provides Caster’s ATK buffs. AR units get a
                damage increase when hitting core but Rei’s base weapon does not
                deal a lot of damage to begin with so that effect is kinda
                useless for her. If you are aiming to get a high rank in Wind
                Weak Raids, you can choose to increase her doll to SR10 or SR15,
                but only if you have already upgraded dolls of other meta units
                since Rei as a DPS is lower priority to upgrade compared to mega
                DPS like Red Hood, Cinderella, Alice, SBS, AsukaW, RapiRH and so
                on.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: Asuka x Rei Team Up Again</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                    <NikkeCharacter mode="icon" slug="grave" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="asuka-shikinami-langley-wille"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rei-ayanami-tentative-name"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="helm-treasure"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                The strongest, yes the strongest, no-core wind comp, featuring 2
                EVA units. Liter Crown and Helm are the best supporters if you
                want to maximise Asuka and Rei’s damage. Since reload is quite
                irrelevant, you can switch between Grave and Crown depending on
                the situation (Crown is still GOAT against A.I Kraken though).
                RapiRH is the best if you are looking for just a single team
                comp and not for multiple teams. With Rei removing wind-up, Rapi
                is the staple alongside her even on Raids where multiple teams
                are required, unless the fire trio of Asuka Rei Mod is in play.
              </p>
              <p>
                Both Raids or Wind-weak Campaign should fear this team. It can
                find itself being used outside Wind-weak Raids, but the boss
                ideally should not have a core for this team to get a solid
                chance of being used.
              </p>
              <h5>Team #2: Rei the Screen Clearing Menace</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rei-ayanami-tentative-name"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="naga" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="d-killer-wife"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                A team for Wind Weak campaign stages. ReiTN can be used as a
                screen clear unit, with RH as B1 buffing her damage. The other
                B3 can be any unit you like here that can deal enough damage or
                perform the job you want them to do so that you clear the stage.
              </p>
              <h5>Team #3: Tornado Bunnies</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="placeholder-box">B1 CDR</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="asuka-shikinami-langley-wille"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rei-ayanami-tentative-name"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="noir" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                Bunnies in 2025! Well they still have their use, especially in
                running braindead MG comps. Noir being a Wind unit helps her
                cause as well. This comp provides a lot of healing amount, a lot
                of ammo for Asuka to shoot (due to Noir’s + Asuka’s ammo
                refund). Bread and butter team, use it when you are too lazy to
                even think. Machine Guns love ammo and make Noir a very happy
                relevant unit.
              </p>
              <h5>Team #4: Maids Ahoy!</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="placeholder-box">B1 CDR</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="mast-romantic-maid"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="asuka-shikinami-langley-wille"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rei-ayanami-tentative-name"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="anchor-innocent-maid"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                Mast and Anchor are the 2nd best buffers for non core bosses.
                They also provide heals, reload speed and burst gen so that
                Asuka and Rei can have a more lenient time when it comes to OL
                gear, and can just focus on dealing damage.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="PvP Team Compositions" />
              <h5>Team #1: 3RL Nuke Wombo Combo</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rei-ayanami-tentative-name"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="miracle-fairy-anne"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                This team has pure stacked power. It allows Rei to deal a
                devastating blow that can kill off a slow enough enemy team, or
                an enemy team that is not instantly killing you.
              </p>
              <h5>Team #2: 2RL Snipe with Ele</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rei-ayanami-tentative-name"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="miracle-fairy-anne"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                    <NikkeCharacter mode="icon" slug="anis" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rumani" enablePopover />
                    <NikkeCharacter mode="icon" slug="anis" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                This team focuses on cleaning units with the help of elemental
                damage on OL gear. Units like Jackal, Red Hood, Centi, Rapunzel,
                LaplaceTreasure, Crown or Liter are often seen being used in
                PvP. A 2RL fast swipe can deal massive damage with the help of
                elemental damage, and can possibly break the burst chain before
                the opponent even bursts! xAnne, and especially Blanc, can help
                ensure that enough damage is dealt after the Burst Nuke that
                whoever is left alive can be taken care of.
              </p>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Strong wind DPS.</li>
                        <li>High damage nuke for both PvP and campaign.</li>
                        <li>Provides a small ATK buff for all allies.</li>
                        <li>Solid DPS against bosses with no core.</li>
                        <li>
                          Synergy with Asuka:Wille to increase her own, Asuka’s
                          and team’s overall damage.
                        </li>
                        <li>
                          Unique buff to MG units, reduces wind up time for MGs.
                        </li>
                        <li>
                          Cool and unique name (fun fact about name in foreword
                          of guide).
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Requires Asuka:Wille to reach her proper potential as
                          a DPS.
                        </li>
                        <li>
                          Burst skill nuke damage scales very harshly, which
                          means that at low skill level it might not do the job.
                        </li>
                        <li>
                          Her base weapon multiplier is lower than meta DPS,
                          hence her damage on core does not scale well.
                        </li>
                        <li>
                          True limited collaboration character. Now or never.
                          Can't get copies in the future.
                        </li>
                        <li>
                          Skill 2 is practically useless and of low value, even
                          though on paper it sounds good.
                        </li>
                        <li>Weird name fr.</li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-5">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesRei2;

export const Head: React.FC = () => (
  <Seo
    title="Rei Ayanami: Tentative Name Guide & Review | NIKKE | Prydwen Institute"
    description="A guide & review for Rei Ayanami: Tentative Name in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
